export default {
	name: 'Header',
	created () {
		this.$nextTick(function () {
			$.each(this.$router.options.routes, (i, n) => {
				if (i >= 1 && i < 6) {
					this.Nav.push(n)
				}
			})
		})
	},
	mounted () {

	},
	inject: [],
	data () {
		return {
			Nav: [],
			NavActive: localStorage.getItem('NavActiveCIMB') || 0
		}
	},
	computed: {

	},
	watch: {

	},
	methods: {
		NavActiveClcik (index) {
			localStorage.setItem('NavActiveCIMB', index)
			this.NavActive = localStorage.getItem('NavActiveCIMB')
			console.log(this.NavActive)
		}
	}
}
