import Vue from 'vue'
import VueRouter from 'vue-router'

// LandingPage
const Home = () =>
	import('@/views/Home/Home.vue')
const Pricing = () =>
	import('@/views/Pricing/Pricing.vue')
const Questions = () =>
	import('@/views/Questions/Questions.vue')
const About = () =>
	import('@/views/About/About.vue')
const Promotions = () =>
	import('@/views/Promotions/Promotions.vue')
const Referralprogram = () =>
	import('@/views/Referralprogram/Referralprogram.vue')
// Footer
const Disclaimers = () =>
	import('@/views/Footer/views/Disclaimers/Disclaimers.vue')
const CorporateAction = () =>
	import('@/views/Footer/views/CorporateAction/CorporateAction.vue')
const PrivacyPolicy = () =>
	import('@/views/Footer/views/PrivacyPolicy/PrivacyPolicy.vue')
const TermsofUse = () =>
	import('@/views/Footer/views/TermsofUse/TermsofUse.vue')
const TermsandConditions = () =>
	import('@/views/Footer/views/TermsandConditions/TermsandConditions.vue')
const RewardsProgrammeTnC = () =>
	import('@/views/Footer/views/RewardsProgrammeTnC/RewardsProgrammeTnC.vue')

// Hidden
const CorporateActions = () =>
	import('@/views/Hidden/views/CorporateActions/CorporateActions.vue')
const TermsofUseforMobileApp = () =>
	import('@/views/Hidden/views/TermsofUseforMobileApp/TermsofUseforMobileApp.vue')
const BiometricsTCs = () =>
	import('@/views/Hidden/views/BiometricsTCs/BiometricsTCs.vue')
const DataSharingTCs = () =>
	import('@/views/Hidden/views/DataSharingTCs/DataSharingTCs.vue')
const Disclaimer_article = () =>
	import('@/views/Hidden/views/Disclaimer_article/Disclaimer_article.vue')
const Disclaimer_dualcurrencies = () =>
	import('@/views/Hidden/views/Disclaimer_dualcurrencies/Disclaimer_dualcurrencies.vue')

Vue.use(VueRouter)

const routes = [
	// 路由重定向
	{
		path: '/',
		redirect: '/up/Home',
		hidden: true,
		meta: {
			title: '',
			content: {
				keywords: '',
				description: ''
			}
		}
	},
	// Home
	{
		path: '/up/Home',
		name: 'Home',
		component: Home,
		meta: {
			name: 'Home',
			title: 'UP | Home',
			content: {
				keywords: '',
				description: ''
			}
		}
	},
	// Pricing
	{
		path: '/up/Pricing',
		name: 'Pricing',
		component: Pricing,
		meta: {
			name: 'Pricing',
			title: 'UP | Pricing',
			content: {
				keywords: '',
				description: ''
			}
		}
	},
	// Questions
	{
		path: '/up/Questions',
		name: 'Questions',
		component: Questions,
		meta: {
			name: 'Questions',
			title: 'UP | Questions',
			content: {
				keywords: '',
				description: ''
			}
		}
	},
	// About
	{
		path: '/up/About',
		name: 'About',
		component: About,
		meta: {
			name: 'About',
			title: 'UP | About Us',
			content: {
				keywords: '',
				description: ''
			}
		}
	},
	// Win Free Flights!
	// {
	// 	path: '/up/WinFreeFlights',
	// 	name: 'WinFreeFlights',
	// 	component: Promotions,
	// 	meta: {
	// 		name: 'Win Free Flights!',
	// 		title: '',
	// 		content: {
	// 			keywords: '',
	// 			description: ''
	// 		}
	// 	}
	// },
	{
		path: '/up/Referralprogram',
		name: 'Promotions',
		component: Referralprogram,
		meta: {
			name: 'Promotions',
			title: 'UP | Promotions',
			content: {
				keywords: '',
				description: ''
			}
		}
	},
	// Footer Disclaimers
	{
		path: '/up/Disclaimers',
		name: 'Disclaimers',
		component: Disclaimers,
		meta: {
			name: 'Disclaimer',
			title: 'UP | Disclaimer',
			content: {
				keywords: '',
				description: ''
			}
		}
	},


	// CorporateAction
	{
		path: '/up/corporateactions',
		name: 'CorporateAction',
		component: CorporateAction,
		meta: {
			name: 'CorporateAction',
			title: 'UP | Corporate Action',
			content: {
				keywords: '',
				description: ''
			}
		}
	},
	// Footer Privacy Policy
	{
		path: '/up/PrivacyPolicy',
		name: 'PrivacyPolicy',
		component: PrivacyPolicy,
		meta: {
			name: 'Privacy Policy',
			title: 'UP | Privacy Policy',
			content: {
				keywords: '',
				description: ''
			}
		}
	},
	// Footer Terms of Use
	{
		path: '/up/TermsofUse',
		name: 'TermsofUse',
		component: TermsofUse,
		meta: {
			name: 'Terms of Use',
			title: 'UP | Terms of Use',
			content: {
				keywords: '',
				description: ''
			}
		}
	},
	// Footer TermsandConditions
	{
		path: '/up/TermsandConditions',
		name: 'TermsandConditions',
		component: TermsandConditions,
		meta: {
			name: 'Terms & Conditions',
			title: 'UP | Terms & Conditions',
			content: {
				keywords: '',
				description: ''
			}
		}
	},
	// Footer RewardsProgrammeTnC
	{
		path: '/up/RewardsProgrammeTnC',
		name: 'RewardsProgrammeTnC',
		component: RewardsProgrammeTnC,
		meta: {
			name: 'Rewards Programme T&Cs',
			title: 'UP | Rewards Programme T&Cs',
			content: {
				keywords: '',
				description: ''
			}
		}
	},
	// Hidden
	{
		path: '/up/TermsofUseMobileApp',
		name: 'TermsofUseforMobileApp',
		component: TermsofUseforMobileApp,
		meta: {
			name: 'Terms of Use – Mobile App',
			title: 'UP | Terms of Use – Mobile App',
			content: {
				keywords: '',
				description: ''
			}
		}
	},
	{
		path: '/up/BiometricTermsnConditions',
		name: 'BiometricsTCs',
		component: BiometricsTCs,
		meta: {
			name: 'Terms & Conditions – Biometrics',
			title: 'UP | Terms & Conditions – Biometrics',
			content: {
				keywords: '',
				description: ''
			}
		}
	},
	{
		path: '/up/DataSharingTnC',
		name: 'DataSharingTCs',
		component: DataSharingTCs,
		meta: {
			name: 'Terms & Conditions – Data Sharing',
			title: 'UP | Terms & Conditions – Data Sharing',
			content: {
				keywords: '',
				description: ''
			}
		}
	},
	{
		path: '/up/Disclaimer_article',
		name: 'Disclaimer_article',
		component: Disclaimer_article,
		meta: {
			name: 'Disclaimer_article',
			title: 'UP | Disclaimer_article',
			content: {
				keywords: '',
				description: ''
			}
		}
	},
	{
		path: '/up/Disclaimer_dualcurrencies',
		name: 'Disclaimer_dualcurrencies',
		component: Disclaimer_dualcurrencies,
		meta: {
			name: 'Disclaimer_dualcurrencies',
			title: 'UP | Disclaimer_dualcurrencies',
			content: {
				keywords: '',
				description: ''
			}
		}
	},

]

// 解决重复触发了同一个路由
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
	return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router
